<template>
  <div class="md-layout">
    <upload-file-card :source="source" :type="type" @uploadFile="getList()">
    </upload-file-card>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-indigo">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Lista de datasets da {{ source }}</h4>
        </md-card-header>

        <md-card-content>
          <md-table
            :value="table"
            :md-sort.sync="sortation.field"
            :md-sort-order.sync="sortation.order"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label>Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Ano" md-sort-by="year">{{
                item.year
              }}</md-table-cell>
              <md-table-cell md-label="Nome" md-sort-by="name">{{
                item.name
              }}</md-table-cell>
              <md-table-cell md-label="Fonte" md-sort-by="source">{{
                item.source
              }}</md-table-cell>
              <md-table-cell md-label="Atualizado em" md-sort-by="updated_at">{{
                item.updated_at
              }}</md-table-cell>
              <md-table-cell md-label="Color">
                <v-swatches
                  v-on:input="changeColor($event, item.id)"
                  v-model="item.color"
                ></v-swatches>
              </md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button
                  v-if="editable"
                  class="md-icon-button md-raised md-round md-info"
                  :href="
                    `/mosaico/datasets/edit/${source}/${type}/${item.year}`
                  "
                  style="margin: .2rem;"
                >
                  <md-icon>create</md-icon>
                </md-button>

                <md-button
                  class="md-icon-button md-raised md-round md-danger"
                  @click="deleteItem(item.id)"
                  style="margin: .2rem;"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @input="setPage"
          />
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import Pagination from "@/components/Pagination";
import { UploadFileCard } from "@/components";

export default {
  components: {
    "upload-file-card": UploadFileCard,
    Pagination,
    VSwatches
  },
  data() {
    return {
      source: String,
      type: String,
      datasetProfile: Object,
      sourcesEditable: ["sesapi"],
      selected: [],
      table: [],
      query: null,

      sortation: {
        field: "updated_at",
        order: "desc"
      },

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 15, 25, 50]
      },

      total: 0
    };
  },
  computed: {
    editable() {
      let result = false;
      this.sourcesEditable.forEach(source => {
        if (source == this.source) {
          result = true;
        }
      });
      return result;
    },
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    }
  },
  created() {
    this.source = this.$route.params.source;
    this.type = this.$route.params.type;
  },
  mounted() {
    this.getList();
    this.getProfile();
  },
  methods: {
    getClass: ({ id }) => ({
      "table-success": id === 1,
      "table-info": id === 3,
      "table-danger": id === 5,
      "table-warning": id === 7
    }),
    setPage(page) {
      this.pagination.currentPage = page;
      this.getList();
    },
    async getProfile() {
      try {
        let result = await Vue.$http.get(
          `v1/type-dataset/profile/${this.source}/${this.type}`
        );
        this.datasetProfile = await result.data;
        this.$emit("welcomeMessage", {
          title: `${this.datasetProfile.alias}: ${this.datasetProfile.lastUpdate}`
        });
      } catch (error) {
        await this.$store.dispatch("alerts/error", error);
      }
    },
    async getList() {
      try {
        let result = await Vue.$http.get(
          `v1/dataset/${this.source}/${this.type}?per_page=${this.pagination.perPage}&page=${this.pagination.currentPage}`
        );
        let dataSets = result.data;
        this.table = dataSets.data;
        this.total = result.data.total;
      } catch (error) {
        await this.$store.dispatch("alerts/error", error);
      }
    },
    async deleteItem(id) {
      try {
        await Vue.$http.delete(`v1/dataset/${this.source}/${this.type}/${id}`);
        this.$store.dispatch("alerts/success", "Dados deletados");
        this.getList();
      } catch (error) {
        this.load = false;
        console.log(error);
        await this.$store.dispatch("alerts/error", error);
        return await error.response;
      }
    },

    customSort() {
      return false;
    },

    async changeColor(color, id) {
      try {
        await Vue.$http.patch(
          `v1/dataset/${this.source}/${this.type}/${id}`,
          {
            color: color
          },
          {
            timeout: 9999999
          }
        );
        this.$store.dispatch("alerts/success", "Cor alterada");
      } catch (error) {
        this.load = false;
        console.log(error);
        return await error.response;
      }
    }
  }
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.vue-swatches__container {
  z-index: 1000 !important;
  position: revert !important;
}
</style>
